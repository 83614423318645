import * as Sentry from "@sentry/react";
import React from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

const {
    REACT_APP_SENTRY_DSN,
    REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
    REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS,
    REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
} = process.env;

Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: Number.parseFloat(
        REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? "0",
    ),
    tracePropagationTargets: [
        new RegExp(`^${REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS}`),
    ],
    replaysSessionSampleRate: Number.parseFloat(
        REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? "0",
    ),
    replaysOnErrorSampleRate: Number.parseFloat(
        REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? "0",
    ),
});
