import clsx from "clsx";
import { getSpacingVariable } from "../../utils";
import styles from "./Gap.module.css";

interface GapProps {
    className?: string;
    height?: string;
}

const Gap = ({ className, height }: GapProps): JSX.Element => {
    return (
        <div
            className={clsx(styles.gap, className)}
            style={{ "--height": getSpacingVariable(height ?? "md") }}
        />
    );
};

export default Gap;
