import clsx from "clsx";
import React from "react";
import styles from "./Icon.module.css";

// Define the props that your icon elements are expected to accept
interface IconProps {
    size?: number;
    stroke?: number;
}

interface CustomIconProps {
    className?: string;
    icon: React.ReactElement<IconProps>;
    color?: string;
    size?: number;
    stroke?: number;
}

const CustomIcon = ({
    className,
    icon,
    color,
    size = 19,
    stroke = 2,
}: CustomIconProps): JSX.Element => {
    return (
        <span
            className={clsx(styles.icon, className)}
            style={{ "--color": color }}
        >
            {React.isValidElement(icon)
                ? React.cloneElement(icon, { size, stroke })
                : icon}
        </span>
    );
};

export default CustomIcon;
