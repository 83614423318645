import { IconAt, IconLink } from "@tabler/icons-react";
import { Chip, Gap, Group, Icon, VisuallyHidden } from "..";
import styles from "./Job.module.css";

interface JobEntry {
    title: string;
    link?: string;
    location?: string;
    dateRange: string;
    summary?: string;
    techStack?: string[];
}

interface JobProps {
    entry: JobEntry;
}

const Job = ({ entry }: JobProps): JSX.Element => {
    const { title, link, location, dateRange, summary, techStack } = entry;

    return (
        <div>
            <Group gap="xs" className={styles["responsive-group"]}>
                <Group gap="sm">
                    <p className={styles.title}>{title}</p>
                    {link && (
                        <a href={link} title={`${title} on GitHub`}>
                            <Icon icon={<IconLink />} />
                        </a>
                    )}
                </Group>
                {location && (
                    <>
                        <Icon
                            icon={<IconAt />}
                            className={styles["responsive-icon"]}
                        />
                        <VisuallyHidden> at </VisuallyHidden>
                        <p>{location}</p>
                    </>
                )}
            </Group>
            <Gap height="0.125rem" />
            <p className={styles["date-range"]}>{dateRange}</p>
            {summary && (
                <>
                    <Gap height="0.375rem" />
                    <p>{summary}</p>
                </>
            )}
            {techStack && techStack.length > 0 && (
                <>
                    <Gap height="sm" />
                    <Group gap="sm">
                        {techStack.map((language, index) => (
                            <Chip
                                // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                                key={index}
                            >
                                {language}
                            </Chip>
                        ))}
                    </Group>
                </>
            )}
        </div>
    );
};

export default Job;
