import * as Sentry from "@sentry/react";
import type { RouteObject } from "react-router-dom";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import styles from "./App.module.css";
import Home from "./Home";
import NotFound from "./NotFound";
import Uses from "./Uses";

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const routes: RouteObject[] = [
    { path: "/", element: <Home /> },
    { path: "/uses", element: <Uses /> },
    { path: "*", element: <NotFound /> },
];

const router = sentryCreateBrowserRouter(routes);

export default function App(): JSX.Element {
    return (
        <main className={styles.wrapper}>
            <RouterProvider router={router} />
        </main>
    );
}
