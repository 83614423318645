import React from "react";
import { Divider, Section } from "./components";
import { Bio } from "./sections";

export default function Uses(): JSX.Element {
    React.useEffect(() => {
        document.title = "Amber Zaragoza";
    }, []);

    return (
        <>
            <Bio />
            <Divider />
            <Section title="Development Tools">
                <ul>
                    <li>
                        Visual Studio Code
                        <ul>
                            <li>Biome</li>
                            <li>Error Lens</li>
                            <li>Ruff</li>
                            <li>Smart Clicks</li>
                        </ul>
                    </li>
                    <li>GitHub</li>
                    <li>GitHub Actions</li>
                    <li>Sentry</li>
                    <li>React</li>
                    <li>Next.js</li>
                </ul>
            </Section>
            <Divider />
            <Section title="Design">
                <ul>
                    <li>Figma</li>
                    <li>GIMP</li>
                    <li>G'MIC</li>
                </ul>
            </Section>
            <Divider />
            <Section title="Productivity">
                <ul>
                    <li>1Password</li>
                    <li>
                        Chrome
                        <ul>
                            <li>React Developer Tools</li>
                            <li>Redux DevTools</li>
                        </ul>
                    </li>
                    <li>Responsively</li>
                    <li>Obsidian</li>
                    <li>uBlock Origin</li>
                    <li>Postman</li>
                </ul>
            </Section>
        </>
    );
}
