import React from "react";
import { Divider, Job as Project, Section } from "../components";
import { projects } from "../data";

const Projects = (): JSX.Element => {
    return (
        <Section title="Projects">
            {projects.map((project, index) => {
                if (index !== projects.length - 1) {
                    return (
                        <React.Fragment
                            // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                            key={index}
                        >
                            <Project entry={project} />
                            <Divider />
                        </React.Fragment>
                    );
                }
                return (
                    <Project
                        // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                        key={index}
                        entry={project}
                    />
                );
            })}
        </Section>
    );
};

export default Projects;
