import React from "react";
import { Job as School, Section } from "../components";
import { school } from "../data";

const Education = (): JSX.Element => {
    return (
        <Section title="Education">
            <School entry={school} />
        </Section>
    );
};

export default Education;
