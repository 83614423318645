export interface School {
    title: string;
    location: string;
    dateRange: string;
}

export const school: School = {
    title: "B.S., Computer Science",
    location: "California State University, Bakersfield",
    dateRange: "September 2014 - May 2019",
};
