import styles from "./Section.module.css";

interface SectionProps {
    title: string;
    children: React.ReactNode;
}

const Section = ({ title, children }: SectionProps): JSX.Element => {
    return (
        <section className={styles.wrapper}>
            <div className={styles.left}>
                <h2 className="text-3xl">{title}</h2>
            </div>
            <div className={styles.right}>{children}</div>
        </section>
    );
};

export default Section;
