import React from "react";
import styles from "./Home.module.css";
import { Divider } from "./components";
import { Bio, Education, Experience, KeySkills, Projects } from "./sections";

export default function Home(): JSX.Element {
    React.useEffect(() => {
        document.title = "Amber Zaragoza";
    }, []);

    return (
        <>
            <Bio />
            <Divider />
            <Experience />
            <Divider />
            <Education />
            <Divider />
            <KeySkills />
            <Divider />
            <Projects />
            <Divider />
            <center>
                <p className={styles.copyright}>
                    Copyright &copy; 2022-{new Date().getFullYear()} Amber
                    Zaragoza. All rights reserved.
                </p>
            </center>
        </>
    );
}
