import {
    IconCloudComputing,
    IconCode,
    IconDatabase,
    IconDevices,
    IconFileCode,
    IconTool,
} from "@tabler/icons-react";

export interface Skillset {
    icon: JSX.Element;
    title: string;
    skills: string[];
}

export const skillsets: Skillset[] = [
    {
        icon: <IconCloudComputing />,
        title: "AWS Cloud Services",
        skills: [
            "API Gateway",
            "Cognito",
            "DynamoDB",
            "Lambda",
            "S3",
            "Step Functions",
        ],
    },
    {
        icon: <IconDatabase />,
        title: "Databases",
        skills: ["MongoDB", "MySQL", "PostgreSQL"],
    },
    {
        icon: <IconFileCode />,
        title: "Frameworks",
        skills: ["Express", "Fastify", "Next.js", "React"],
    },
    {
        icon: <IconDevices />,
        title: "Platforms",
        skills: ["ProntoForms", "Smartsheet", "Zoho Analytics"],
    },
    {
        icon: <IconCode />,
        title: "Programming Languages",
        skills: ["CSS", "HTML", "JavaScript", "Node.js", "Python", "SQL"],
    },
    {
        icon: <IconTool />,
        title: "Tools",
        skills: [
            "Bash",
            "Docker",
            "Git",
            "GitHub Actions",
            "Postman",
            "VS Code",
        ],
    },
];

export const processedSkillsets: Skillset[] = skillsets.map((skillset) => {
    if (skillset.skills.length > 3 && skillset.skills.length < 6) {
        const blankElements = Array(6 - skillset.skills.length).fill("");
        return { ...skillset, skills: skillset.skills.concat(blankElements) };
    }
    return skillset;
});

if (processedSkillsets.length % 2 !== 0) {
    processedSkillsets.push({
        icon: <span />,
        title: "Placeholder",
        skills: [],
    });
}
