export interface Project {
    title: string;
    dateRange: string;
    summary: string;
    techStack: string[];
    link?: string;
}

export const projects: Project[] = [
    {
        title: "Kollectr",
        dateRange: "May 2023 - Present",
        summary:
            "Web application that allows users to keep track of their K-Pop album, photocard, and merchandise collections.",
        techStack: ["React"],
    },
    {
        title: "Lynx",
        link: "https://github.com/amberzaragoza/seniorproject-frontend",
        dateRange: "August 2018 - May 2019",
        summary:
            "User-based mobile application that utilizes the user's location to locate businesses in real-time.",
        techStack: ["React Native", "Spring Boot", "PostgreSQL"],
    },
    {
        title: "Shiba Survival",
        link: "https://github.com/amberzaragoza/Shiba-Survival",
        dateRange: "January 2019 - May 2019",
        summary:
            "Video game in which you help a Shiba Inu survive various types of enemies.",
        techStack: ["C++", "OpenGL"],
    },
];
