type Size = "xs" | "sm" | "md" | "lg" | "xl";

const getSpacingVariable = (size: Size | string): string => {
    const sizes = ["xs", "sm", "md", "lg", "xl"];
    if (sizes.includes(size)) {
        return `var(--spacing-${size})`;
    }

    if (/\d(px|rem)$/.test(size)) {
        return size;
    }

    return "var(--spacing-lg)";
};

export default getSpacingVariable;
