import clsx from "clsx";
import styles from "./Chip.module.css";

interface ChipProps {
    className?: string;
    children: React.ReactNode;
}

const Chip = ({ className, children }: ChipProps): JSX.Element => (
    <p className={clsx(styles.chip, "text-xs", "text-medium", className)}>
        {children}
    </p>
);

export default Chip;
