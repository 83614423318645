import clsx from "clsx";
import { getSpacingVariable } from "../../utils";
import styles from "./Group.module.css";

interface GroupProps {
    className?: string;
    align?: string;
    justify?: string;
    gap?: string;
    children: React.ReactNode;
}

const Group = ({
    className,
    align,
    justify,
    gap,
    children,
}: GroupProps): JSX.Element => {
    return (
        <div
            className={clsx(styles.group, className)}
            style={{
                "--align": align,
                "--justify": justify,
                "--gap": getSpacingVariable(gap ?? "md"),
            }}
        >
            {children}
        </div>
    );
};

export default Group;
