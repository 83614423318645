export interface Job {
    title: string;
    location: string;
    dateRange: string;
    summary: string;
}

export const jobs: Job[] = [
    {
        title: "Senior Software Developer",
        location: "Cornerstone Engineering, Inc",
        dateRange: "July 2023 - Present",
        summary:
            "In my role as a senior software developer, I focus on the intersection of technology and user experience, dedicating my efforts to producing software solutions that adhere to best practices in UI/UX and accessibility. This commitment is driven by collaboration with clients and an understanding of user needs.",
    },
    {
        title: "Developer",
        location: "Stria - A Bitwise Industries Company",
        dateRange: "February 2021 - June 2023",
        summary:
            "As a developer and team leader, I designed, built, and maintained multiple full-stack web applications for a diverse set of industries. Notable accomplishments include developing a document management system for a large-scale utility company, efficiently handling approximately 900,000 documents and serving nearly 2,000 users.",
    },
    {
        title: "Computer Science Lecturer",
        location: "California State University, Bakersfield",
        dateRange: "August 2019 - May 2021",
        summary:
            "Instructed 5 undergraduate courses, mainly focused on full-stack web application development, as well as procedural and object-oriented programming with C/C++. Responsibilities included lesson planning, delivering lectures and labs, grading assignments and exams, and providing students with support during office hours.",
    },
];
