import { Divider, Group, Icon } from "..";
import styles from "./Skillset.module.css";

interface IconProps {
    size?: number;
    stroke?: number;
}

interface SkillsetProps {
    icon: React.ReactElement<IconProps>;
    title: string;
    skills: string[];
}

const Skillset = ({ icon, title, skills = [] }: SkillsetProps): JSX.Element => {
    return (
        <div className={styles.skill}>
            <Group gap="sm">
                {icon && <Icon icon={icon} />}
                <span style={{ fontWeight: 500 }}>{title}</span>
            </Group>
            <Divider margin="sm" />
            <ul style={{ columnCount: skills.length > 3 ? 2 : 1 }}>
                {skills.map((skill, index) => {
                    if (skill !== "") {
                        return (
                            <li
                                // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                                key={index}
                            >
                                {skill}
                            </li>
                        );
                    }
                    return (
                        <li
                            // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                            key={index}
                            style={{ listStyle: "none" }}
                        >
                            &nbsp;
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Skillset;
