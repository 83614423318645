import {
    IconBrandGithub,
    IconBrandLinkedin,
    IconFile,
    IconMail,
    IconSparkles,
} from "@tabler/icons-react";
import { Gap, Group, Icon, VisuallyHidden } from "../components";
import styles from "./Bio.module.css";

const Bio = (): JSX.Element => {
    return (
        <header className={styles.wrapper}>
            <div className={styles.left}>
                <h1 className="text-3xl">
                    Hello, I&apos;m Amber!{" "}
                    <Icon
                        icon={<IconSparkles />}
                        size={24}
                        className={styles.sparkles}
                    />
                </h1>
                <Gap height="sm" />
                <p>
                    A full-stack web developer located in Bakersfield, CA. I am
                    passionate about providing clients with user-friendly
                    applications catered to their unique needs. I am currently
                    working as a Senior Software Developer.
                </p>
                <Gap height="0.75rem" />
                <Group className={styles["link-group"]}>
                    <a
                        role="button"
                        className={styles["link-button"]}
                        href="mailto:amber@amberzaragoza.com"
                        title="Email Amber Zaragoza"
                    >
                        <IconMail />
                        <VisuallyHidden>Email</VisuallyHidden>
                    </a>
                    <a
                        role="button"
                        className={styles["link-button"]}
                        href="/files/AmberZaragoza_Resume.pdf"
                        title="View Amber Zaragoza's r&eacute;sum&eacute;"
                    >
                        <IconFile />
                        <VisuallyHidden>R&eacute;sum&eacute;</VisuallyHidden>
                    </a>
                    <a
                        role="button"
                        className={styles["link-button"]}
                        href="https://www.linkedin.com/in/amber-zaragoza"
                        title="Visit Amber Zaragoza's LinkedIn profile"
                    >
                        <IconBrandLinkedin />
                        <VisuallyHidden>LinkedIn</VisuallyHidden>
                    </a>
                    <a
                        role="button"
                        className={styles["link-button"]}
                        href="https://github.com/amberzaragoza"
                        title="Explore Amber Zaragoza's GitHub repositories"
                    >
                        <IconBrandGithub />
                        <VisuallyHidden>GitHub</VisuallyHidden>
                    </a>
                </Group>
            </div>
            <div className="right">
                <img
                    className={styles.image}
                    src="/images/portrait-drawing.jpg"
                    alt="Drawing of Amber Zaragoza"
                />
            </div>
        </header>
    );
};

export default Bio;
