import clsx from "clsx";
import { getSpacingVariable } from "../../utils";
import styles from "./Divider.module.css";

interface DividerProps {
    className?: string;
    margin?: string;
}

const Divider = ({ className, margin }: DividerProps): JSX.Element => {
    return (
        <hr
            className={clsx(styles.divider, className)}
            style={{ "--margin": getSpacingVariable(margin ?? "lg") }}
        />
    );
};

export default Divider;
